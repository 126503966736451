import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, AutoComplete, InputRef, Spin } from "antd";
import { useSelector } from "react-redux";
import clsx from "clsx";
import axios, { CancelTokenSource } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import debounce from "debounce";
import createSuggestions from "../../utils/suggest/createSuggestions";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import ButtonSearch from "../buttons/ButtonSearch";
import Scanner from "../Scanner/Scanner";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import {
  delays,
  locationSearchQueryParameter,
  routePathNames,
} from "../../appConfig";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import useGetProductDetailLink from "../../hooks/useGetProductDetailLink";
import elementScrollToPosition from "../../utils/elementScrollToPosition";

interface Props {
  className: string;
}

/**
 * quick order component with encapsulated logics
 * @param {string} className
 */
const SearchInput: React.FC<Props> = function SearchInput({
  className,
}: Props) {
  const setUpdateUrlFragments = useUpdateUrlFragments();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isWeekPlannerEnabled = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

  const { [locationSearchQueryParameter.searchTerm]: searchQuery } =
    queryString.parse(search);
  const getProductDetailLink = useGetProductDetailLink();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const scanButtonRef = useRef<HTMLButtonElement | null>(null);
  const autoCompleteWrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputRef | null>(null);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const [options, setOptions] = useState<null | any[]>(null);
  const [searchTerm, setSearchTerm] = useState<string>(
    String(searchQuery || "")
  );
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [hasGoToDetailPageIntend, setHasGoToDetailPageIntend] =
    useState<boolean>(false);

  /**
   * update input and button
   * @param {string} value
   */
  const updateComponentStates = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCreateSuggestions = useCallback(
    debounce(async (value: string) => {
      cancelTokenSource.current.cancel("Operation canceled by user");
      cancelTokenSource.current = cancelAndRenewCancelToken(
        cancelTokenSource.current
      );

      if (value.length >= 3) {
        setIsLoading(true);
        setIsAutocompleteOpen(true);

        await createSuggestions({
          query: value,
          deliveryDate,
          cancelTokenSource: cancelTokenSource.current,
          type: "search",
          isHeaderSearch: true,
          showBrand: true,
          showProductGroup: true,
          showCompletion: true,
          context: pathname.includes(routePathNames.weekPlanner)
            ? "weekplanner"
            : "productlist",
          updateComponentStates,
        })
          .then((result: any) => {
            setIsLoading(false);
            setOptions(result);
          })
          .catch((error: any) => {
            if (!axios.isCancel(error)) {
              setIsLoading(false);
            }
          });
      }
    }, delays.openSearchInput),
    [createSuggestions, deliveryDate]
  );

  /**
   * input change handler
   * @param {string} value
   */
  const onSearch = (value: string) => {
    updateComponentStates(value);
    debounceCreateSuggestions(value);
  };

  /**
   * navigates the user to a product detail page
   * @param {string} sku
   * @param {any} option
   */
  const goToDetailPage = (sku: string, option: any): void => {
    setIsAutocompleteOpen(false);
    if (hasGoToDetailPageIntend && !!sku) {
      navigate(getProductDetailLink(sku));
    }
    if (hasGoToDetailPageIntend && option?.to) {
      navigate(option.to);
    }
  };

  /**
   * go to search result page with searched value
   */
  const goToSearchPage = () => {
    debounceCreateSuggestions.flush(); // Ensure the debounced function is immediately executed
    setIsLoading(false);
    cancelTokenSource.current.cancel();

    setIsAutocompleteOpen(false);
    const isWeekplanner = pathname.includes(routePathNames.weekPlanner);

    const context = isWeekplanner ? "searchWeekPlanner" : "searchProductList";

    const targetPathname =
      isWeekplanner && isWeekPlannerEnabled
        ? `${routePathNames.weekPlanner}${routePathNames.search}`
        : `${routePathNames.products}${routePathNames.search}`;

    const { deliveryDateFrom, deliveryDateTo } = queryString.parse(search);
    const from = deliveryDateFrom?.length ? deliveryDateFrom.toString() : null;
    const to = deliveryDateTo?.length ? deliveryDateTo.toString() : null;

    elementScrollToPosition({ top: 0 });

    setUpdateUrlFragments({
      context,
      targetPathname,
      parameters: {
        searchTerm,
        page: 1,
        deliveryDate,
        from,
        to,
      },
      showFilters: false,
    });
  };
  /**
   * try to get the elements width and add them up
   * if the value is falsy, return true, so the dropdown
   * is at least as wide as the input
   * @return {number | boolean}
   */
  const getDropdownWidth = () => {
    const autoCompleteWidth = autoCompleteWrapperRef.current?.offsetWidth || 0;
    const searchButtonWidth = searchButtonRef.current?.offsetWidth || 0;
    const scanButtonWidth = scanButtonRef.current?.offsetWidth || 0;

    if (
      Number.isNaN(autoCompleteWidth) ||
      Number.isNaN(searchButtonWidth) ||
      Number.isNaN(scanButtonWidth)
    ) {
      return true; // Or handle the case where one of the widths is not a number
    }

    return 1.2 * (autoCompleteWidth + searchButtonWidth + scanButtonWidth);
  };

  useEffect(() => {
    const onScroll = () => {
      setIsAutocompleteOpen(false);
    };

    if (searchTerm.length === 0) {
      setIsAutocompleteOpen(false);
    }

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [searchTerm]);

  /*
   * Add an event listener to AutoComplete to check if a user intends
   * to visit the product detail page when searching for products
   * (e.g. by pressing down arrow key)
   * This is necessary because AutoComplete component makes an option active
   * when the option value equals AutoComplete input (searchTerm)
   */
  useEffect(() => {
    const autoComplete = autoCompleteWrapperRef.current;
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown") {
        setHasGoToDetailPageIntend(true);
      }
    };

    autoComplete.addEventListener("keydown", keyDownEventHandler);
    return () => {
      autoComplete.removeEventListener("keydown", keyDownEventHandler);
      setHasGoToDetailPageIntend(false);
    };
  }, []);

  /*
   * Reset hasGoToDetailPageIntend state after AutoComplete was closed
   */
  useEffect(() => {
    if (!isAutocompleteOpen) {
      setHasGoToDetailPageIntend(false);
    }
  }, [isAutocompleteOpen]);

  /*
   * Disable scrolling on body if AutoComplete is open
   */
  useEffect(() => {
    const eventTypes: string[] = ["mousewheel", "touchmove"];

    const disableScrolling = (e: Event) => {
      if (e.cancelable) {
        e.preventDefault();
      }
    };

    if (isAutocompleteOpen) {
      eventTypes.forEach((eventType: string) => {
        document.body.addEventListener(eventType, disableScrolling, {
          passive: false,
        });
      });
    } else {
      eventTypes.forEach((eventType: string) => {
        document.body.removeEventListener(eventType, disableScrolling);
      });
    }

    return () => {
      eventTypes.forEach((eventType: string) => {
        document.body.removeEventListener(eventType, disableScrolling);
      });
    };
  }, [isAutocompleteOpen]);

  /*
   * Close AutoComplete on outside click
   */
  useEffect(() => {
    const closeAutoComplete = (e: Event) => {
      if ((e.target as HTMLElement)?.id !== "searchInput__autocomplete") {
        setIsAutocompleteOpen(false);
      }
    };

    document.body.addEventListener("click", closeAutoComplete);

    return () => {
      document.body.removeEventListener("click", closeAutoComplete);
    };
  }, []);

  const handlePopupScroll = () => {
    inputRef?.current?.blur();
  };

  return (
    <div className={clsx("quickSearch autocompleteSearch", className)}>
      <div className="autoCompleteWrapper" ref={autoCompleteWrapperRef}>
        <AutoComplete
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={2}
          id="searchInput__autocomplete"
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={onSearch}
          value={searchTerm}
          className="autocompleteInput"
          disabled={false}
          open={isAutocompleteOpen}
          dropdownMatchSelectWidth={getDropdownWidth()}
          notFoundContent={isLoading ? <Spin /> : "Keine Produkte gefunden."}
          options={options}
          onSelect={goToDetailPage}
          dropdownClassName="suggestDropdown"
          onPopupScroll={handlePopupScroll}
          listHeight={screenHeight * 0.8}
        >
          <Input
            ref={inputRef}
            allowClear
            size="large"
            placeholder="Produktsuche"
            className="quickSearchInput"
            disabled={false}
            onPressEnter={() => {
              goToSearchPage();
              inputRef?.current?.select();
            }}
            onFocus={() => {
              inputRef?.current?.select();
              if (searchTerm.length) {
                setIsAutocompleteOpen(true);
              }
            }}
            onClick={() => {
              inputRef?.current?.select();
            }}
            onMouseEnter={() => debounceCreateSuggestions(searchTerm)}
            onMouseLeave={() => debounceCreateSuggestions.clear()}
          />
        </AutoComplete>
      </div>

      <ButtonSearch onClick={goToSearchPage} ref={searchButtonRef} />

      <Scanner ref={scanButtonRef} />
    </div>
  );
};

export default SearchInput;
